import React from "react";

function Post2() {
    return(
    <div className="alt-page-container">
      <div className='intro'>
        this is a test for post2
      </div>
     </div>
    );
}

export default Post2;