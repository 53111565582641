import React from "react";

function Post1() {
    return(
    <div className="alt-page-container">
      <div className='intro'>
        this is a test for post1
      </div>
     </div>
    );
}

export default Post1;